import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import styled from "styled-components";
import { uploadFile } from "upload/uploadFile";
import { FaAlignCenter, FaAlignLeft, FaTrash } from "react-icons/fa";
import { useWindowSize } from "react-use-size";
import { fileMap } from "../TipTap";
import { useUserId } from "hooks/UseUserId";



// Define KindImage Node
export const KindImage = Node.create({
  name: "kindImage",
  group: "block",
  draggable: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: { default: null }, // Unique ID for file tracking
      src: { default: null }, // Uploaded image URL
      naturalWidth: { default: null }, // Aspect ratio for image
      naturalHeight: { default: null }, // Aspect ratio for image
      widthPct: { default: 100 }, // Percent width of the image
      align: { default: "center" }, // Image alignment
    };
  },

  parseHTML() {
    return [{ tag: "img" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(KindImageComponent);
  },


});

const KindImageComponent = ({ node, updateAttributes, editor }) => {
  const { id, src } = node.attrs;
  const userId = useUserId()
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false); // Tracks upload initiation
  const [editingSize, setEditingSize] = useState(false)
  const [isEditable, setIsEditable] = useState(editor.isEditable);

  const imageRef = useRef(null); // Reference for ImageContainer

  const onUploadComplete = (url) => {
    updateAttributes({ src: url });
    fileMap.delete(id); // Clean up file reference
  };

  const onImageClick = () => {
    if (!isEditable) return;
    setEditingSize(!editingSize)
  }

  const onDelete = () => {
    // Find the position of the node (this requires the node's position in the document)
    const nodePos = editor.state.doc.resolve(editor.state.selection.$anchor.pos);

    // Get the node size (length of the node)
    const nodeSize = node.nodeSize;

    // Delete the node using its position and size
    editor.chain()
      .focus()
      .deleteRange({ from: nodePos.pos, to: nodePos.pos + nodeSize })
      .run();
  };

  // Update editable state when the editor state changes
  editor.on('transaction', () => {
    setIsEditable(editor.isEditable);
  });

  const onResizeButtonClick = (widthPct) => {
      updateAttributes({ widthPct })
  }

  const onAlignClick = (alignString) => {
    updateAttributes({ align: alignString })
  }

  // Effect 1: Trigger upload if it hasn't started yet
  useEffect(() => {
    if (src || uploadStarted) return; // Avoid re-triggering upload

    const file = fileMap.get(id);
    if (file) {
      console.log("Starting upload for ID:", id);
      setUploadStarted(true); // Mark upload as initiated
    }
  }, [id, src, uploadStarted]);

  // Effect 2: Log or handle state changes to uploadStarted
  useEffect(() => {
    if (uploadStarted && !src) {
      const file = fileMap.get(id);
      if (file) {
        const url = URL.createObjectURL(file);
        const img = document.createElement("img");
        img.src = url;
        img.onload = () => {
          const update = {
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight,
          }
          updateAttributes(update);
          URL.revokeObjectURL(url); // Clean up object URL
        };
      }
      const storageDir = userId
      uploadFile(
        file,
        storageDir,
        onUploadComplete,
        setUploadProgress,
        (error) => {
          console.error("Upload failed:", error);
          setUploadStarted(false); // Allow retry in case of failure
        },
        id
      );
    }
  }, [uploadStarted]);

  // Effect: Detect clicks outside the ImageContainer
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imageRef.current && !imageRef.current.contains(event.target)) {
        setEditingSize(false);
      }
    };

    if (editingSize) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingSize]);

  return (
    <NodeViewWrapper>
      {!src ?
        <UploadingPlaceholder
          uploadProgress={uploadProgress}
        /> :
        <div ref={imageRef}>
          <RenderedImg

            nodeAttrs={node.attrs}
            editingSize={editingSize}
            onImageClick={onImageClick}
            onResizeButtonClick={onResizeButtonClick}
            onDelete={onDelete}
            onAlignClick={onAlignClick}
          />
        </div>
      }
    </NodeViewWrapper>
  );
};

const RenderedImg = ({ nodeAttrs, editingSize, onImageClick, onResizeButtonClick, onDelete, onAlignClick }) => {
  const windowSize = useWindowSize()

  const { src, widthPct, naturalWidth, naturalHeight, align } = nodeAttrs;
  // Pre-calculate height in pixels to reserve space
  

  const docWidth = 650;

  const targetWidth = docWidth * (widthPct / 100)

  const widthStyle = widthPct ? `${targetWidth}px` : "100%";

  const aspectRatio = naturalWidth / naturalHeight

  console.log('aspectRatio', aspectRatio)
  console.log('widthStyle', widthStyle)

  console.log('windowSize', windowSize)

  return (
    <NodeViewWrapper data-drag-handle draggable>
      <ImageOuter style={{justifyContent: align}}>
        <ImageContainer style={{ width: widthStyle, aspectRatio }}>
          <img
            style={{
              width: "100%", // Ensures the image fills the container
              height: "100%", // Forces the container to take up reserved height
            }}
            onClick={onImageClick}
            src={src}
            alt="Uploaded"
            loading="lazy" // Optional for better UX
          />
          {editingSize && (
            <SizeEditor
              onDelete={onDelete}
              onResizeButtonClick={onResizeButtonClick}
              onAlignClick={onAlignClick}
            />
          )}
        </ImageContainer>
      </ImageOuter>

    </NodeViewWrapper>
  );
}

const ImageOuter = styled.div`
  display: flex;
  width: 100%;
  height:100%;
`

const UploadingPlaceholder = ({ uploadProgress }) => {
  return (
    <div className="indented">
      <Placeholder
        className="indented"
      >
        Uploading... {uploadProgress}%
      </Placeholder>
    </div>
  )
}


const SizeEditor = ({ onResizeButtonClick, onDelete, onAlignClick }) => {
  return <Dropdown>
    <button
      onClick={() => onResizeButtonClick(100)}
      className="btn btn-sm btn-light"
    >Full</button>
    <button
      onClick={() => onResizeButtonClick(90)}
      className="btn btn-sm btn-light"
    >90%</button>
    <button
      onClick={() => onResizeButtonClick(80)}
      className="btn btn-sm btn-light"
    >80%</button>
    <button
      onClick={() => onResizeButtonClick(70)}
      className="btn btn-sm btn-light"
    >70%</button>
    <button
      onClick={() => onResizeButtonClick(60)}
      className="btn btn-sm btn-light"
    >60%</button>
    <button
      onClick={() => onResizeButtonClick(50)}
      className="btn btn-sm btn-light"
    >Half</button>
    <button
      onClick={() => onAlignClick('left')}
      className="btn btn-sm btn-light"
    ><FaAlignLeft/></button>
    <button
      onClick={() => onAlignClick('center')}
      className="btn btn-sm btn-light"
    ><FaAlignCenter/></button>
    <button
      onClick={onDelete}
      className="btn btn-sm btn-light"
    ><FaTrash /></button>
  </Dropdown>
}

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`



// Styled Components
const Placeholder = styled.div`
  width: 100%;
  height: 200px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-style: italic;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
`;
