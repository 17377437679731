import { useEffect, useState } from "react";
import { FaExternalLinkAlt, FaLink, FaPlay } from "react-icons/fa";
import styled from "styled-components";
import YouTube from "react-youtube";
import { useIsMobile } from "hooks/UseWindowSize";
const rowHeight = 80;

export const SongLink = ({ songLink, artist, title }) => {
  console.log(songLink)
  if(songLink == null || songLink == "") {
    return <EmptyLink></EmptyLink>
  
  }
  
  if (songLink.includes('youtu')) {
    return <YouTubeRow link={songLink} />
  }

  if (songLink.includes('soundcloud')) {
    return <SoundCloudRow link={songLink} artist={artist} title={title} />
  }

  if (songLink.includes('spotify')) {
    return (
      <Spotify link={songLink} />
    )
  } else {
    return (
      <SongRow>
        <LinkButton link={songLink} />
      </SongRow>
    )
  }
}

const SoundCloudRow = ({ link, artist, title }) => {

  const [artworkUrl, setArtworkUrl] = useState(null);

  const isMobile = useIsMobile()

  const getArtworkFromiTunes = async (artist, title) => {
    const response = await fetch(
      `https://itunes.apple.com/search?term=${encodeURIComponent(artist + ' ' + title)}&media=music&limit=1`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      return data.results[0].artworkUrl100.replace('100x100', '500x500'); // Higher res
    } else {
      return null; // No artwork found
    }
  };

  // Example usage in React
  useEffect(() => {
    const fetchArtwork = async () => {
      const artwork = await getArtworkFromiTunes(artist, title);
      setArtworkUrl(artwork);
    };
    fetchArtwork();
  }, [artist, title]);

  if (isMobile) {
    return <SoundCloudPlayer link={link} />
  }

  return (
    <SongRow>
      <Artwork artworkUrl={artworkUrl} link={link} />
      {/* <LinkButton link={link} /> */}
      <SoundCloudPlayer link={link} />
    </SongRow>
  );
}

const SoundCloudPlayer = ({ link }) => {
  return <iframe
    width="300"
    height={rowHeight}
    scrolling="no"
    frameBorder="no"
    allow="autoplay"
    src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(link)}`}
    style={{ borderRadius: '8px' }}
  ></iframe>
}

const YouTubeRow = ({ link }) => {
  const isMobile = useIsMobile()
  function getYouTubeVideoId(url) {
    const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|embed)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const videoId = getYouTubeVideoId(link);
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`


  if (isMobile) {
    return <YouTube videoId={videoId} opts={{
      height: rowHeight,
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    }} />;
  }

  return (
    <SongRow>
      <Artwork artworkUrl={thumbnailUrl} link={link} />
      {/* <LinkButton link={link} /> */}
      <YouTube videoId={videoId} opts={{
        height: rowHeight,
        width: '300',
        playerVars: {
          autoplay: 0,
        },
      }} />;
    </SongRow>
  );
}


const Spotify = ({ link, style = {}, ...props }) => {

  const isMobile = useIsMobile()
  const [artworkUrl, setArtworkUrl] = useState(null);

  // Fetch the oEmbed data to get the artwork URL
  useEffect(() => {
    const fetchArtwork = async () => {
      const oEmbedUrl = `https://open.spotify.com/oembed?url=${encodeURIComponent(link)}`;
      try {
        const response = await fetch(oEmbedUrl);
        const data = await response.json();
        setArtworkUrl(data.thumbnail_url);
      } catch (error) {
        console.error('Error fetching artwork:', error);
      }
    };

    fetchArtwork();
  }, [link]);

  // Generate the Spotify embed URL for a preview
  const embedUrl = `https://open.spotify.com/embed${new URL(link).pathname}`;

  if(isMobile) {
    return <SpotifyPlayer embedUrl={embedUrl} width="100%"/>
  }

  return (
    <SongRow>
      <Artwork artworkUrl={artworkUrl} link={link} />
      {/* <LinkButton link={link} /> */}
      <SpotifyPlayer embedUrl={embedUrl} width="300px"/>
    </SongRow>
  );
};

const EmptyLink = styled.div`
  height: ${rowHeight}px;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  `

const SpotifyPlayer = ({ embedUrl, width }) => {
  return <iframe
    title="Spotify Preview"
    src={embedUrl}
    width={width}
    height={rowHeight}
    frameBorder="0"
    allow="encrypted-media"
    className=""
    style={{ marginTop: '0px', borderRadius: '8px' }}
  ></iframe>
}

const Artwork = ({ artworkUrl, link }) => {
  if (!artworkUrl) {
    return <ImagePlaceholder />
  }

  return <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  ><img
      src={artworkUrl}
      alt="Spotify Artwork"
      width={rowHeight}
      height={rowHeight}
      style={{ borderRadius: 8 }}
    /></a>
}

const ImagePlaceholder = styled.div`
  width: ${rowHeight}px;
  height: ${rowHeight}px;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

const LinkButton = ({ link }) => {
  return <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="btn btn-light"
    style={{ height: rowHeight, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <FaExternalLinkAlt />&nbsp;Song Link
  </a>
}

const SongRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
`;