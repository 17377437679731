import styled from "styled-components"



export const Quote = styled.div`
font-style: italic;
    color: #555;
    border-left: 3px solid #ccc;
    padding-left: 10px;
    margin: 10px 0;
    font-family: 'Georgia', serif;
`

export const SongTitle = styled.h2`
  font-size: 1.6em;
  margin: 0;
  color: #222;
`

export const SongRow = styled.div`
  display: flex;
  flex-direction: column;
`