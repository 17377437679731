import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
  setDoc,
} from "firebase/firestore";
import { useDebounce } from 'use-debounce/lib';
import deepEqual from 'fast-deep-equal'
import { getStorage } from 'firebase/storage';


import { v4 as uuidv4 } from "uuid";
import * as awarenessProtocol from "y-protocols/awareness.js";

import {
  FaAlignCenter,
  FaBold,
  FaHeading,
  FaImage,
  FaPython,
  FaSquareRootAlt,
} from "react-icons/fa";
import Swal from "sweetalert2";

// tiptap
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import Placeholder from "@tiptap/extension-placeholder";

import { RunnableCode } from "./extensions/RunnableCode";
import { InlineTex } from "./extensions/InlineTex";
import { BlockTex } from "./extensions/BlockTex";

// yjs

import './TipTap.scss';
import { ButtonBar } from "./ButtonBar";
import { getApp } from "firebase/app";
import { KindImage } from "./extensions/KindImage";
import { handleDrop } from "./handlers/handleDrop";

export const TipTapNoColab = ({
  editable,
  firebaseDocPath,
  user,
  contentKey,
  minHeight
}) => {

  console.log('asdf')

  // listen to changes to the firebase document path
  const [serverData, serverDataLoading, serverDataError] = useDocumentDataOnce(
    doc(getFirestore(), firebaseDocPath)
  );

  if (!firebaseDocPath) {
    return <>No firebaseDocPath</>;
  }

  if (serverDataLoading) return <></>;
  if (serverDataError) return <p>Error loading data</p>;

  // might have to inject a value
  let initialData = serverData ? serverData[contentKey] : ''
  console.log(contentKey)
  console.log(initialData)
  return (
    <TipTapWithData
      serverData={initialData}
      firebaseDocPath={firebaseDocPath}
      editable={editable}
      user={user}
      minHeight={minHeight} 
    />
  );
};

/**
 * This is the actual editor
 */
const TipTapWithData = ({ serverData, editable, firebaseDocPath, user, minHeight}) => {

  const [cachedContent, setCachedContent] = useState(serverData)

  // we dont want to write when the data first loads
  // useRef object will persist for the full lifetime of the component.
  const isFirstDebounce = useRef(); 
  useEffect(() => {
    // starts false! 
    isFirstDebounce.current = false
  },[])
  
  // debouncedExplanation is a lagged version of currExplanation (by 250ms). When it changes, we'll update the database with the explanation
  const [debouncedContent] = useDebounce(cachedContent, 250);

  // Whenever our debouncedContent changes, we need to update the database
  useEffect(() => {
    if (debouncedContent) {
      
      // only save if this isn't the data we just loaded from the db
      // option for optimization: you could short circuit the deepequal.
      // dont save if there hasn't been a change
      let isOriginal = deepEqual(serverData,debouncedContent)
      let shouldSave = !isOriginal && editable
      if(!isOriginal && editable) {
        isFirstDebounce.current = false
      }
      if(shouldSave) {
        console.log('saving...')
        isFirstDebounce.current = false
        setDoc(doc(getFirestore(), firebaseDocPath), { 
            comment: cachedContent,
            lastEdit: new Date()
           },
          { merge: true }
        )
        .catch(() => {
          // note that this is not fired when the internet is turned off!! 
          // https://stackoverflow.com/questions/60850409/firebase-set-object-in-firestore-never-catches-errors
          alert('error')
        })
      }
    }
  }, [debouncedContent]);

  
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // The Collaboration extension comes with its own history handling
        // (you don't undo other peoples changes)
        history: false,
      }),
      InlineTex,
      BlockTex,
      RunnableCode,
      Placeholder.configure({
        placeholder: "Write something …",
      }),
      Image.configure({
        inline: false,
      }),
      KindImage
    ],
    editable: editable,
    content:serverData
  });

  useEffect(() => {
    if (editor) {
      editor.setEditable(editable);
      editor.chain().focus().insertContent("").run();
    }
  }, [editable]);

  useEffect(() => {
    if (!editor) return;
    editor.on("update", ({ editor }) => {
      let json = editor.getJSON();
      let html = editor.getHTML();
      let text = editor.getText();
      setCachedContent(json)
    });
  }, [editor]);

  const uploadImg = (file) => {
    const storage = getStorage(getApp());
    const storageRef = storage.ref();
    const folder = user.uid;
    const uuid = uuidv4();
    const uploadTask = storageRef.child(folder + "/" + uuid).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress, snapshot.bytesTransferred, snapshot.totalBytes);
        if (progress == 100) {
          Swal.fire({
            title: `Uploading image: Complete`,
            toast: true,
            timer: 2000,
            showConfirmButton: false,
            timerProgressBar: true,
            showClass: {
              popup: "none",
            },
          });
        } else {
          Swal.fire({
            title: `Uploading image: ${progress}%`,
            toast: true,
            timer: 10000,
            showClass: {
              popup: "none",
            },
            showConfirmButton: false,
          });
        }
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          insertImage(url);
        });
      }
    );
  };

  const onInsertImage = () => {
    Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    }).then((e) => {
      const file = e.value;
      if (file) {
        uploadImg(file);
      }
    });
  };

  // Handle drag-and-drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const insertImage = (imgUrl) => {
    editor.chain().focus().insertContent(`<img src="${imgUrl}"></img>`).run();
  };

  return (
    <div className="tiptapWrapper" 
    onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, editor)}>
      <ButtonBar
        editor={editor}
        editable={editable}
        onInsertImage={onInsertImage}
      />
      <div className={editable ? "editor" : ""}>
        <EditorContent 
            editor={editor} 
        />
      </div>
    </div>
  );
};

