import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { fileMap } from "../TipTap";

/**
 * Custom handleDrop function for TipTap Editor
 * @param {EditorView} view - The ProseMirror editor view
 * @param {Event} event - The drop event
 * @param {Slice} slice - The content being dropped
 * @param {boolean} moved - Indicates if the drop was an internal move
 * @returns {boolean} - True if handled, false otherwise
 */
export const handleDrop = (view, event, slice, moved) => {
  console.log("HANDLE DROP");
  console.log("Moved:", moved);
  
  // If the drop is an internal move, let TipTap handle it
  if (moved) {
    console.log("Internal move detected. Letting TipTap handle it.");
    return false;
  }

  // Otherwise, handle it as an external drop
  console.log("External drop detected. Handling externally.");

  // Prevent default behavior to handle the drop manually
  event.preventDefault();

  // Check for external data: files or image URLs
  const hasFiles = event.dataTransfer.files && event.dataTransfer.files.length > 0;
  const htmlContent = event.dataTransfer.getData("text/html");
  const uriContent = event.dataTransfer.getData("text/uri-list");

  let imageUrl = null;

  // Handle file drops
  if (hasFiles) {
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      console.log("Handling file drop:", file);
      const id = uuidv4();

      // Store the file in the global file map
      fileMap.set(id, file);

      const state = view.state;

      // Insert KindImage node with the placeholder ID
      console.log('Insertion position:', view.state.selection.$from.pos);
      view.dispatch(
        view.state.tr.insert(slice.openStart, view.state.schema.nodes.kindImage.create({ id, src: null }))
      );


      return true; // Indicate that the drop was handled
    }
  }

  // Handle external image URLs
  if (htmlContent) {
    // Extract image URL from HTML content (common with dragging images from browsers)
    const doc = new DOMParser().parseFromString(htmlContent, "text/html");
    const imgElement = doc.querySelector("img");
    if (imgElement) imageUrl = imgElement.src;
  } else if (uriContent) {
    // Use direct URI content if available
    imageUrl = uriContent;
  }

  if (imageUrl) {
    console.log("Handling image URL drop:", imageUrl);
    // Fetch and upload the external image
    fetchImageAndUpload(imageUrl, view, slice);
    return true; // Indicate that the drop was handled
  }

  // If no relevant data is found, do not handle the drop
  console.log("No relevant data found in drop. Not handling.");
  return false;
};

/**
 * Fetches an image from a URL and uploads it by inserting a KindImage node
 * @param {string} url - The URL of the image to fetch
 * @param {EditorView} view - The ProseMirror editor view
 */
const fetchImageAndUpload = async (url, view, slice) => {
  try {
    const response = await fetch(url, { mode: "cors" });
    if (!response.ok) throw new Error("Failed to fetch the image");

    const blob = await response.blob();
    const fileName = `external_${uuidv4()}.jpg`; // Generate a unique filename

    // Create a File object from the blob
    const file = new File([blob], fileName, { type: blob.type });
    const id = uuidv4();

    // Store file temporarily
    fileMap.set(id, file);

    // Insert KindImage node with the placeholder ID
    view.dispatch(
      view.state.tr.insert(slice.openStart, view.state.schema.nodes.kindImage.create({ id, src: null }))
    );

    // console.log(editor)
    
    // // Insert placeholder KindImage node
    // editor
    //   .chain()
    //   .focus()
    //   .insertContent({
    //     type: "kindImage",
    //     attrs: { id, src: null },
    //   })
    //   .run();
  } catch (error) {
    console.error("Error fetching and uploading image:", error);
    Swal.fire("Error", "Failed to upload the image from the external source.", "error");
  }
};