import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import API_ROUTE from '../ApiRoute.js';
import axios from 'axios'
import Swal from 'sweetalert2'
import {
  getGracePeriodDeadline
} from "../utils/PSetUtil.js"
import { GRADING_DEFAULT_POINTS, calcGradeFraction, checkify } from '../grading/GradingUtil.js';
import { Extensions } from './Extensions.jsx';


// global date options
export const dateOptions = {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'long'
}

export const PSetSplash = ({ publicPsetData, allFeedback, studentPsetData, user }) => {
  let { qtrId, psetId } = useParams();


  if (!publicPsetData) return <></>

  let firstId = publicPsetData.questionInfo[0].qId

  console.log(publicPsetData)

  const noDeadline = publicPsetData?.noDeadline // defaults to false
  const hasDeadline = !noDeadline






  return <div className="container-fluid" style={{ background: '#f7f7f7', minHeight: '100vh', height: '100%' }}>
    <div className='row'>
      <div className='col'>
        <div style={{ maxWidth: 800, textAlign: 'center', padding: '20px', background: 'white', height: '100vh' }}>
          <h2>{publicPsetData['title']}</h2>
          <h3>For {user.displayName}</h3>
          <hr />

          <div className="mb-3">
            <Link className="btn btn-primary" to={`/${qtrId}/${psetId}/${firstId}`}>Get Started</Link>
          </div>

          {
            (publicPsetData.gradesReleased || false) &&
            <GradingSummary
              publicPsetData={publicPsetData}
              studentPsetData={studentPsetData}
              allFeedback={allFeedback}
              user={user}
              qtrId={qtrId}
              psetId={psetId}
            />
          }

          {
            (hasDeadline) &&
            <Extensions
              publicPsetData={publicPsetData}
              studentPsetData={studentPsetData}
              user={user}
            />
          }
        </div>
      </div>
    </div>
  </div>
}

const GradingSummary = (props) => {
  // need grade data here.
  // get everything for this collection
  // make sure that grades have been released

  let gradeReport = []
  let gradeSum = 0
  let nItems = 0
  let gradeSumMax = 0
  let feedbackFormat = ('gradeReportType' in props.publicPsetData ? props.publicPsetData.gradeReportType : 'check')
  for (const question of props.publicPsetData.questionInfo) {
    let feedback = props.allFeedback[question.qId]
    let rawGrade = calcGradeFraction(feedback)
    // return rawGrade.points , rawGrade.maxPoints
    let gradeStr = ''
    if (rawGrade != null) {
      gradeStr = checkify(rawGrade.points, rawGrade.maxPoints, feedbackFormat)
      gradeSum += rawGrade.points ? rawGrade.points : 0

    }
    gradeReport.push({
      'title': question.title,
      'grade': gradeStr
    })

    if (!question.isExtraCredit) {
      gradeSumMax += (rawGrade && rawGrade.maxPoints) ? rawGrade.maxPoints : GRADING_DEFAULT_POINTS
    } else {
      console.log("extra credit doesn't count against your gradeSumMax")
    }

    nItems++
  }
  let overallGradeFormat = feedbackFormat != 'check' ? 'percentage' : 'check'
  let overallGrade = checkify(gradeSum, gradeSumMax, feedbackFormat)
  console.log(gradeSum, gradeSumMax)
  gradeReport.push({
    'title': <b>Overall</b>,
    'grade': overallGrade
  })

  return <div className='bordered'>
    <h3>Grade Summary</h3>
    <table className='table table-hover' style={{ textAlign: 'left' }}>
      <thead>
        <tr>
          <th>Question</th>
          <th>TA Grade</th>
        </tr>
      </thead>
      <tbody>
        {gradeReport.map((item, index) => {
          return <tr key={index}>
            <td>{item.title}</td>
            <td>{item.grade}</td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}