
export function dateToWeekIndex(dateTime) {
  // the first day is 0:01am on monday Sept 30, 2024 Pacific Time
  const firstDay = new Date('2024-09-30T00:00:01-07:00');
  const timeDiff = dateTime - firstDay;
  const weeksInMs = 1000 * 60 * 60 * 24 * 7;
  return Math.floor(timeDiff / weeksInMs);
}

export function dateToDayIndex(dateTime) {
  // The first day is 0:01am on Monday, Sept 30, 2024, Pacific Time
  const firstDay = new Date('2024-09-30T00:00:01-07:00');
  const timeDiff = dateTime - firstDay; // Time difference in milliseconds
  const daysInMs = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
  return Math.floor(timeDiff / daysInMs);
}