import React, { useState, useEffect, useRef } from "react";
import {
  FaAlignCenter,
  FaBold,
  FaHeading,
  FaImage,
  FaPython,
  FaSquareRootAlt,
} from "react-icons/fa";
import { fileMap } from "./TipTap";
import { v4 as uuidv4 } from 'uuid';

export const ButtonBar = ({ editor, editable, onInsertImage }) => {
    if (!editable) return <></>;
    return (
      <div
        className="d-flex"
        style={{
          top: "0",
          background: "white",
          border: "1px solid lightgrey",
          borderRadius: "5px 5px 0px 0px",
          maxWidth:'670px'
        }}
      >
  
        <BlockLatexButton editor={editor}/>
        <InlineLatexButton editor={editor}/>
        <PythonButton editor={editor}/>
        <ImageButton editor={editor}/>
        
  
      </div>
    );
  };
  
  
  
  function InlineLatexButton({editor}) {
    return <button onClick={() =>
      editor
        .chain()
        .focus()
        .insertContent("<inline-tex></inline-tex>")
        .run()
    } className="btn btn-sm btn-light">
  <FaSquareRootAlt /> Inline LaTeX
  </button>
  }
  
  export const ImageButton = ({ editor }) => {
    const fileInputRef = useRef(null);
  
    const onClick = () => {
      // Trigger the file input click event to open the file picker
      fileInputRef.current.click();
    };
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
  
      if (file && file.type.startsWith("image/")) {
        const id = uuidv4();
  
        // Store the file in the global file map
        fileMap.set(id, file);
  
        // Insert the KindImage node with the placeholder ID
        editor
          .chain()
          .focus()
          .insertContent({
            type: "kindImage",
            attrs: { id, src: null },
          })
          .run();
      }
    };
  
    return (
      <>
        <button onClick={onClick} className="btn btn-sm btn-light">
          <FaImage /> Image
        </button>
  
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </>
    );
  }
  
  function PythonButton({editor}) {
    return <button
    onClick={() =>
      editor
        .chain()
        .focus()
        .insertContent("<runnable-code></runnable-code>")
        .run()
    }
    className="btn btn-sm btn-light "
  >
    <FaPython /> Python
  </button>
  }
  
  function BlockLatexButton({editor}) {
    return <button onClick={() => editor
      .chain()
      .focus()
      .insertContent("<block-tex></block-tex>")
      .run()} className="btn btn-sm btn-light">
      <FaAlignCenter /> Block LaTeX
    </button>;
  }