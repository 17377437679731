import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app, { storage } from "firebaseApp"; 
import {uuidv4} from 'lib0/random';

export const uploadFile = (
  file, 
  storageDir, 
  onUploadComplete, 
  setUploadProgress, 
  setErrorState,
  fileId=null
) => {
  // Start the upload process
  setUploadProgress(1);

  // Get the storage instance
 

  // Get a unique identifier for the file
  if(!fileId) {
    fileId = uuidv4();
  } 

  const storagePath = `${storageDir}/${fileId}`;

  // Create a reference to the storage path
  const storageRef = ref(storage, storagePath);

  // Start the file upload
  const uploadTask = uploadBytesResumable(storageRef, file);

  // Monitor the upload progress
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setUploadProgress(progress);
    },
    (error) => {
      setErrorState({
        code: error.code,
        message: error.message,
      });
    },
    () => {
      // Retrieve the file's download URL after upload completion
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        onUploadComplete(url, file.name, fileId);
      });
    }
  );
};
