import { useEffect, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import API_ROUTE from "ApiRoute";
import axios from "axios";
import { useUser } from "contexts/UserContext";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { RateSongs, RatingTitle } from "./components/RateSongs";
import { Handout } from "components/handout/Handout";
import { useQuarterId } from "hooks/UseQuarterId";
import { dateToDayIndex } from "./MusicUtil";
const functions = getFunctions();


export const RegularVoting = () => {
  /**
   * Before the tournament starts, we have the regular season. Each week,
   * users can rate songs. The songs to rate are determined by the algorithm
   */

  const qtrId = useQuarterId();

  const db = getFirestore();

  const userId = useUser().uid
  const [allVotes, allVotesLoading] = useDocumentDataOnce(doc(db, `music/${qtrId}/public/allVotes`));
  const [songData, songDataLoading] = useDocumentDataOnce(doc(db, `music/${qtrId}/public/songs`));

  if (allVotesLoading || songDataLoading) {
    return <div>Loading...</div>
  }

  const currTimePeriodVotes = getRatingsForCurrTimePeriod(allVotes, userId)
  console.log('currTimePeriodVotes', currTimePeriodVotes)

  const hasUndefined = Object.values(currTimePeriodVotes).some(v => v === undefined)
  const hasSongsToRate = Object.keys(currTimePeriodVotes).length > 0

  const shouldRate = hasUndefined && hasSongsToRate

  console.log('shouldRate', shouldRate)
  console.log('hasSongsToRate', hasSongsToRate)
  console.log('hasUndefined', hasUndefined)

  if (shouldRate) {
    return <RateSongs currTimePeriodVotes={currTimePeriodVotes} songData={songData} />
  }

  if(!hasSongsToRate) {
    return <RequestSongs songData={songData}/>
  }

  if(!hasUndefined) {
    return <RateSongs currTimePeriodVotes={currTimePeriodVotes} songData={songData} editable={false}/>
  }

  return <></>


}

function getRatingsForCurrTimePeriod(allVotes, userId) {
  const timePeriodIndex = dateToDayIndex(new Date());
  // allVotes keys are <songId>.<userId>
  const matches = {}
  for (const voteKey in allVotes) {
    // split on the .
    const parts = voteKey.split('.');
    const voteUserId = parts[1];
    if (voteUserId == userId) {

      // make sure the vote is for the current week
      const voteDate = allVotes[voteKey].timeStamp.toDate(); // Ensure timeStamp is converted properly
      const voteTimePeriodIndex = dateToDayIndex(voteDate);
      if (voteTimePeriodIndex == timePeriodIndex) {
        const songId = parts[0];
        matches[songId] = allVotes[voteKey].score;
      } 
    }
  }
  return matches;
}

const RequestSongs = ({songData}) => {
  const qtrId = useQuarterId()
  const [songVotes, setSongVotes] = useState({})
  let user = useUser()

  const querySongs = async () => {
    const toRate = await requestSongsToRate(user, qtrId)
    console.log('toRate', toRate)
    const votes = {}
    for (const song of toRate) {
      votes[song] = undefined
    }
    console.log('votes', votes)
    setSongVotes(votes)
  }

  useEffect(() => {
    querySongs()
  }, []);

  if(Object.keys(songVotes).length === 0) {
    return <Handout element={<>Running probability algorithm</>}/>
  }


  return (
    <RateSongs currTimePeriodVotes={songVotes} songData={songData} />
  )
}

async function requestSongsToRate(user, qtrId) {
  const token = await user.getIdToken(true)
  let URL = `https://us-central1-guia-cs109psets.cloudfunctions.net/getSongsToRate`;
  const response = await axios.post(URL, {
    data: {
      token: token,
      qtrId: qtrId
    }
  })
  console.log('response', response)
  return response.data.result.songsToRate
}