import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/functions";
import { getStorage } from "firebase/storage";

const hostname = window.location.hostname;
const isPsetApp = hostname === "cs109psets.netlify.app";

// if you are at cs106a.stanford.edu, use that domain
// if you are at codeinplace.stanford.edu, use that domain
// otherwise, use the firebaseapp domain

let authDomain = "guia-cs109psets.firebaseapp.com";
if (isPsetApp) {
  authDomain = hostname;
}

export const firebaseConfig = {
  apiKey: "AIzaSyCnbk0pBaCjf7oI2Byz1hL9UlKrHhY207Q",
  authDomain: `${authDomain}`,
  databaseURL: "https://guia-cs109psets-default-rtdb.firebaseio.com",
  projectId: "guia-cs109psets",
  storageBucket: "guia-cs109psets.appspot.com",
  messagingSenderId: "281483229198",
  appId: "1:281483229198:web:66835fb4fee6be50d9645f",
  measurementId: "G-SXFTTQYK54",
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const database = firebase.database();
const functions = firebase.app().functions('us-central1');
const storage = getStorage(app);
const auth = firebase.auth;


export default firebase;
export { firestore, database, auth, functions, storage};


